// set sortField of pushNotification
export const SET_PUSH_NOTIFICATION_SORT_FIELD =
  "SET_PUSH_NOTIFICATION_SORT_FIELD";

// set sortOrder of pushNotification
export const SET_PUSH_NOTIFICATION_SORT_ORDER =
  "SET_PUSH_NOTIFICATION_SORT_ORDER";

// set pageNo of pushNotification
export const SET_PUSH_NOTIFICATION_PAGE = "SET_PUSH_NOTIFICATION_PAGE";

// set sizePerPage of pushNotification
export const SET_PUSH_NOTIFICATION_SIZE_PER_PAGE =
  "SET_PUSH_NOTIFICATION_SIZE_PER_PAGE";

// fetch all Push Notification
export const FETCH_PUSH_NOTIFICATIONS = "FETCH_PUSH_NOTIFICATIONS";
export const FETCH_PUSH_NOTIFICATIONS_SUCCESS =
  "FETCH_PUSH_NOTIFICATIONS_SUCCESS";
export const FETCH_PUSH_NOTIFICATIONS_ERROR = "FETCH_PUSH_NOTIFICATIONS_ERROR";

// add Push Notification
export const ADD_PUSH_NOTIFICATION = "ADD_PUSH_NOTIFICATION";
export const ADD_PUSH_NOTIFICATION_SUCCESS = "ADD_PUSH_NOTIFICATION_SUCCESS";
export const ADD_PUSH_NOTIFICATION_ERROR = "ADD_PUSH_NOTIFICATION_ERROR";
