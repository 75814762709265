import {
  ADD_RECIPE,
  ADD_RECIPE_ERROR,
  ADD_RECIPE_SUCCESS,
  EDIT_RECIPE,
  EDIT_RECIPE_ERROR,
  EDIT_RECIPE_SUCCESS,
  FETCH_FILTERS,
  FETCH_FILTERS_ERROR,
  FETCH_FILTERS_SUCCESS,
  FETCH_RECIPE,
  FETCH_RECIPE_ERROR,
  FETCH_RECIPE_SUCCESS,
  GET_ALL_RECIPE_CATEGORIES,
  FILTER_BY_STATUS,
  GET_RECIPE_DATA,
  GET_RECIPE_DATA_ERROR,
  GET_RECIPE_DATA_SUCCESS,
  SET_RECIPE_PAGE,
  SET_RECIPE_SEARCH,
  SET_RECIPE_SIZE_PER_PAGE,
  SET_RECIPE_SORT_FIELD,
  SET_RECIPE_SORT_ORDER,
  SET_RECIPE_STATUS,
  GET_RECIPE_BY_CATEGORY_FILTER,
} from "./actionTypes";

const initialState = {
  loading: false,
  recipes: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  sortOrder: "",
  sortField: "",
  search: "",
  filterBy: 0,
  filters: [],
  loadingAddEditRecipeSave: false,
  measurements: [],
  recipeCategories: [],
  ingredients: [],
  recipeTags: [],
  getAllRecipeCategories: [],
  filterRecipeDataByCategory: [],
};

const recipesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set recipe page
    case SET_RECIPE_PAGE:
      return {
        ...state,
        page: payload,
      };
    // set recipe sizePerPage
    case SET_RECIPE_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    // set recipe sortField
    case SET_RECIPE_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    // set recipe sortOrder
    case SET_RECIPE_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // set recipe search
    case SET_RECIPE_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // set Filter By Status
    case FILTER_BY_STATUS:
      return {
        ...state,
        filterBy: payload,
      };
    // fetch all filters
    case FETCH_FILTERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        filters: payload,
        loading: false,
      };
    case FETCH_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    // fetch recipe
    case FETCH_RECIPE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RECIPE_SUCCESS:
      return {
        ...state,
        recipes: payload.rows,
        totalSize: payload.count,
        loading: false,
      };
    case FETCH_RECIPE_ERROR:
      return {
        ...state,
        loading: false,
      };
    // add recipe
    case ADD_RECIPE:
      return {
        ...state,
        loadingAddEditRecipeSave: true,
      };
    case ADD_RECIPE_SUCCESS:
      return {
        ...state,
        loadingAddEditRecipeSave: false,
      };
    case ADD_RECIPE_ERROR:
      return {
        ...state,
        loadingAddEditRecipeSave: false,
      };
    //edit recipe
    case EDIT_RECIPE:
      return {
        ...state,
        loadingAddEditRecipeSave: true,
      };
    case EDIT_RECIPE_SUCCESS:
      return {
        ...state,
        loadingAddEditRecipeSave: false,
      };
    case EDIT_RECIPE_ERROR:
      return {
        ...state,
        loadingAddEditRecipeSave: false,
      };
    // get all recipe dropdown data
    case GET_RECIPE_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_RECIPE_DATA_SUCCESS:
      return {
        ...state,
        measurements: payload.recipe_measurement,
        recipeCategories: payload.recipe_category,
        ingredients: payload.ingredient,
        recipeTags: payload.tags,
        loading: false,
      };
    case GET_RECIPE_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    // set recipe status
    case SET_RECIPE_STATUS:
      let newRecipeData = state.recipes.map((x) => {
        if (x.recipe_id === payload.recipe_id) {
          x.status = payload.status;
        }
        return x;
      });
      return {
        ...state,
        recipes: newRecipeData,
      };
    // get all recipe categories
    case GET_ALL_RECIPE_CATEGORIES:
      return {
        ...state,
        getAllRecipeCategories: payload.getAllrecArrList
      }

    // get recipe by categories filter
    case GET_RECIPE_BY_CATEGORY_FILTER:
      return {
        ...state,
        filterRecipeDataByCategory: payload
      }
    default:
      return state;
  }
};

export default recipesReducer;
