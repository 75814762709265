import {
  ADD_PUSH_NOTIFICATION,
  ADD_PUSH_NOTIFICATION_ERROR,
  ADD_PUSH_NOTIFICATION_SUCCESS,
  FETCH_PUSH_NOTIFICATIONS,
  FETCH_PUSH_NOTIFICATIONS_ERROR,
  FETCH_PUSH_NOTIFICATIONS_SUCCESS,
  SET_PUSH_NOTIFICATION_PAGE,
  SET_PUSH_NOTIFICATION_SIZE_PER_PAGE,
  SET_PUSH_NOTIFICATION_SORT_FIELD,
  SET_PUSH_NOTIFICATION_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  loading: false,
  pushNotifications: [],
  totalSize: 0,
  sizePerPage: 10,
  sortOrder: "",
  sortField: "",
  page: 1,
  loadingAddPushNotification: false,
};

const pushNotificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set pushNotification page
    case SET_PUSH_NOTIFICATION_PAGE:
      return {
        ...state,
        page: payload,
      };

    // set pushNotification sizePerPage
    case SET_PUSH_NOTIFICATION_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };

    // set pushNotification sortField
    case SET_PUSH_NOTIFICATION_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    // set pushNotification sortOrder
    case SET_PUSH_NOTIFICATION_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };

    // fetch all push notification
    case FETCH_PUSH_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PUSH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        pushNotifications: payload.rows,
        totalSize: payload.count,
        loading: false,
      };
    case FETCH_PUSH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    // add push notification
    case ADD_PUSH_NOTIFICATION:
      return {
        ...state,
        loadingAddPushNotification: true,
      };
    case ADD_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingAddPushNotification: false,
      };
    case ADD_PUSH_NOTIFICATION_ERROR:
      return {
        ...state,
        loadingAddPushNotification: false,
      };
    default:
      return state;
  }
};

export default pushNotificationReducer;
