import {
  FETCH_GUIDES,
  FETCH_GUIDES_SUCCESS,
  FETCH_GUIDES_ERROR,
  SET_GUIDES_SEARCH,
  SET_GUIDES_PAGE,
  SET_GUIDES_SIZE_PER_PAGE,
  SET_GUIDES_SORT_FIELD,
  SET_GUIDES_SORT_ORDER,
  ADD_GUIDES,
  ADD_GUIDES_SUCCESS,
  ADD_GUIDES_ERROR,
  EDIT_GUIDE,
  EDIT_GUIDES_SUCCESS,
  EDIT_GUIDES_ERROR,
  DRAG_AND_DROP_GUIDE,
  SET_GUIDE_STATUS,
} from "./actionTypes";

const initialState = {
  loading: false,
  guides: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  sortOrder: "",
  sortField: "",
  search: "",
  loadingAddEditGuidesSave: false,
  allGuides: []
};

const guideReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set guides PageNo
    case SET_GUIDES_PAGE:
      return {
        ...state,
        page: payload,
      };

    // set guides siePerPage
    case SET_GUIDES_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };

    // set guides sortField
    case SET_GUIDES_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    // set guides sortOrder
    case SET_GUIDES_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };

    // set guides search
    case SET_GUIDES_SEARCH:
      return {
        ...state,
        search: payload,
      };

    // fetch guides
    case FETCH_GUIDES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GUIDES_SUCCESS:
      return {
        ...state,
        guides: payload.rows,
        totalSize: payload.count,
        loading: false,
      };
    case FETCH_GUIDES_ERROR:
      return {
        ...state,
        loading: false,
      };
    //add guides
    case ADD_GUIDES:
      return {
        ...state,
        loadingAddEditGuidesSave: true,
      };
    case ADD_GUIDES_SUCCESS:
      return {
        ...state,
        loadingAddEditGuidesSave: false,
      };
    case ADD_GUIDES_ERROR:
      return {
        ...state,
        loadingAddEditGuidesSave: false,
      };

    // edit guides
    case EDIT_GUIDE:
      return {
        ...state,
        loadingAddEditGuidesSave: true,
      };
    case EDIT_GUIDES_SUCCESS:
      return {
        ...state,
        loadingAddEditGuidesSave: false,
      };
    case EDIT_GUIDES_ERROR:
      return {
        ...state,
        loadingAddEditGuidesSave: false,
      };
    // drag and drop guide
    case DRAG_AND_DROP_GUIDE:
      return {
        ...state,
        guides: payload,
      };
    // Draft / LIVE Toggle guide
    case SET_GUIDE_STATUS:
      let newGuideData = state.guides.map((x) => {
        if (x.guides_id === payload.guides_id) {
          x.status = payload.status;
        }
        return x;
      });
      return {
        ...state,
        guides: newGuideData,
      };
    default:
      return state;
  }
};

export default guideReducer;
