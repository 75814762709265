import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  SET_PRODUCTS_SEARCH,
  SET_PRODUCTS_PAGE,
  SET_PRODUCTS_SIZE_PER_PAGE,
  SET_PRODUCTS_SORT_FIELD,
  SET_PRODUCTS_SORT_ORDER,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_ERROR,
  DRAG_AND_DROP_PRODUCT,
} from "./actionTypes";

const initialState = {
  loading: false,
  products: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  sortOrder: "",
  sortField: "",
  search: "",
  loadingProductSave: false,
};

const guideReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set product PageNo
    case SET_PRODUCTS_PAGE:
      return {
        ...state,
        page: payload,
      };

    // set product siePerPage
    case SET_PRODUCTS_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };

    // set product sortField
    case SET_PRODUCTS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    // set product sortOrder
    case SET_PRODUCTS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };

    // set product search
    case SET_PRODUCTS_SEARCH:
      return {
        ...state,
        search: payload,
      };

    //fetch product
    case FETCH_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload.rows,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    //add product
    case ADD_PRODUCT:
      return {
        ...state,
        loadingProductSave: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingProductSave: false,
      };
    case ADD_PRODUCT_ERROR:
      return {
        ...state,
        loadingProductSave: false,
      };

    // edit product
    case EDIT_PRODUCT:
      return {
        ...state,
        loadingProductSave: true,
      };
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingProductSave: false,
      };
    case EDIT_PRODUCT_ERROR:
      return {
        ...state,
        loadingProductSave: false,
      };
    // drag and drop product
    case DRAG_AND_DROP_PRODUCT:
      return {
        ...state,
        products: payload,
      };
    default:
      return state;
  }
};

export default guideReducer;
