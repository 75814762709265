// login
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

//logout
export const LOGOUT_USER = "LOGOUT_USER";

//api error
export const API_ERROR = "API_ERROR";

//set login message
export const SET_LOGIN_MESSAGE = "SET_LOGIN_MESSAGE";

//fetch user profile
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR";
