// set user page
export const SET_USERS_PAGE = "SET_USERS_PAGE";

// set user search
export const SET_USERS_SEARCH = "SET_USERS_SEARCH";

// set user sortField
export const SET_USERS_SORT_FIELD = "SET_USERS_SORT_FIELD";

// set user sortOrder
export const SET_USERS_SORT_ORDER = "SET_USERS_SORT_ORDER";

// set user sizePerPage
export const SET_USERS_SIZE_PER_PAGE = "SET_USERS_SIZE_PER_PAGE";

// get all users
export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_ERROR = "FETCH_ALL_USERS_ERROR";

// edit Users
export const EDIT_USERS = "EDIT_USERS";
export const EDIT_USERS_SUCCESS = "EDIT_USERS_SUCCESS";
export const EDIT_USERS_ERROR = "EDIT_USERS_ERROR";
