import {
  EDIT_USERS,
  EDIT_USERS_ERROR,
  EDIT_USERS_SUCCESS,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_ERROR,
  FETCH_ALL_USERS_SUCCESS,
  SET_USERS_PAGE,
  SET_USERS_SEARCH,
  SET_USERS_SIZE_PER_PAGE,
  SET_USERS_SORT_FIELD,
  SET_USERS_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  loading: false,
  users: [],
  page: 1,
  sizePerPage: 10,
  sortOrder: "",
  sortField: "",
  search: "",
  totalSize: 0,
  total_accounts: 0,
  total_subscribers: 0,
  loadingUsersSave: false,
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set user page
    case SET_USERS_PAGE:
      return {
        ...state,
        page: payload,
      };
    // set user sizePerPage
    case SET_USERS_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    // set user search
    case SET_USERS_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // set user sortField
    case SET_USERS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    // set user sortOrder
    case SET_USERS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // get all users
    case FETCH_ALL_USERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: payload.userDetails.rows,
        total_accounts: payload.total_accounts,
        total_subscribers: payload.total_subscribers,
        totalSize: payload.userDetails.count,
        loading: false,
      };
    case FETCH_ALL_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    // edit Users
    case EDIT_USERS:
      return {
        ...state,
        loadingUsersSave: true,
      };
    case EDIT_USERS_SUCCESS:
      return {
        ...state,
        loadingUsersSave: false,
      };
    case EDIT_USERS_ERROR:
      return {
        ...state,
        loadingUsersSave: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
