import {
  SET_AISLES_PAGE,
  SET_AISLES_SIZE_PER_PAGE,
  SET_AISLES_SORT_FIELD,
  SET_AISLES_SORT_ORDER,
  SET_AISLES_SEARCH,
  FETCH_AISLES,
  FETCH_AISLES_SUCCESS,
  FETCH_AISLES_ERROR,
  ADD_AISLE,
  ADD_AISLE_SUCCESS,
  ADD_AISLE_ERROR,
  EDIT_AISLE,
  EDIT_AISLE_SUCCESS,
  EDIT_AISLE_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  aisles: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  sortOrder: "",
  sortField: "",
  search: "",
  loadingAisleSave: false,
};

const aislesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //set aisles page
    case SET_AISLES_PAGE:
      return {
        ...state,
        page: payload,
      };
    //set aisles sizePerPage
    case SET_AISLES_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    //set aisles sortField
    case SET_AISLES_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    // set aisles sortOrder
    case SET_AISLES_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // set aisles search
    case SET_AISLES_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // fetch all aisles
    case FETCH_AISLES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AISLES_SUCCESS:
      return {
        ...state,
        aisles: payload.rows,
        totalSize: payload.count,
        loading: false,
      };
    case FETCH_AISLES_ERROR:
      return {
        ...state,
        loading: false,
      };
    // add aisles
    case ADD_AISLE:
      return {
        ...state,
        loadingAisleSave: true,
      };
    case ADD_AISLE_SUCCESS:
      return {
        ...state,
        loadingAisleSave: false,
      };
    case ADD_AISLE_ERROR:
      return {
        ...state,
        loadingAisleSave: false,
      };
    // edit aisle
    case EDIT_AISLE:
      return {
        ...state,
        loadingAisleSave: true,
      };
    case EDIT_AISLE_SUCCESS:
      return {
        ...state,
        loadingAisleSave: false,
      };
    case EDIT_AISLE_ERROR:
      return {
        ...state,
        loadingAisleSave: false,
      };
    default:
      return state;
  }
};

export default aislesReducer;
