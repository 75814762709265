import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import Loader from "components/common/Loader";
import store from "./store";

import "assets/css/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = lazy(() => import("./App"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </Provider>
  </BrowserRouter>
);
