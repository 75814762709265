// set sortField of aisles
export const SET_AISLES_SORT_FIELD = "SET_AISLES_SORT_FIELD";

// set sortOrder of aisles
export const SET_AISLES_SORT_ORDER = "SET_AISLES_SORT_ORDER";

// set pageNo of aisles
export const SET_AISLES_PAGE = "SET_AISLES_PAGE";

// set sizePerPage of aisles
export const SET_AISLES_SIZE_PER_PAGE = "SET_AISLES_SIZE_PER_PAGE";

// set search of aisles
export const SET_AISLES_SEARCH = "SET_AISLES_SEARCH";

// fetch all aisles
export const FETCH_AISLES = "FETCH_AISLES";
export const FETCH_AISLES_SUCCESS = "FETCH_AISLES_SUCCESS";
export const FETCH_AISLES_ERROR = "FETCH_AISLES_ERROR";

// add aisles
export const ADD_AISLE = "ADD_AISLE";
export const ADD_AISLE_SUCCESS = "ADD_AISLE_SUCCESS";
export const ADD_AISLE_ERROR = "ADD_AISLE_ERROR";

// edit aisles
export const EDIT_AISLE = "EDIT_AISLE";
export const EDIT_AISLE_SUCCESS = "EDIT_AISLE_SUCCESS";
export const EDIT_AISLE_ERROR = "EDIT_AISLE_ERROR";
