import {
  SET_ADMIN_PAGE,
  SET_ADMIN_SIZE_PER_PAGE,
  SET_ADMIN_SORT_FIELD,
  SET_ADMIN_SORT_ORDER,
  SET_ADMIN_SEARCH,
  FETCH_ADMINS,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_ERROR,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_ERROR,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_ERROR,
  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  admins: [],
  totalSize: 0,
  sizePerPage: 10,
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  loadingAdminSave: false,
  permissions: [],
};

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set admin page
    case SET_ADMIN_PAGE:
      return {
        ...state,
        page: payload,
      };

    //set admin sizePerPage
    case SET_ADMIN_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };

    //set admin sortField
    case SET_ADMIN_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    //ser admin sortOrder
    case SET_ADMIN_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };

    // set admin search
    case SET_ADMIN_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // fetch all admins
    case FETCH_ADMINS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        admins: payload.rows,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_ADMINS_ERROR:
      return {
        ...state,
        loading: false,
      };
    // add admin
    case ADD_ADMIN:
      return {
        ...state,
        loadingAdminSave: true,
      };
    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        loadingAdminSave: false,
      };
    case ADD_ADMIN_ERROR:
      return {
        ...state,
        loadingAdminSave: false,
      };
    // edit Admin
    case EDIT_ADMIN:
      return {
        ...state,
        loadingAdminSave: true,
      };
    case EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        loadingAdminSave: false,
      };
    case EDIT_ADMIN_ERROR:
      return {
        ...state,
        loadingAdminSave: false,
      };
    // get permissions
    case GET_PERMISSION:
      return {
        ...state,
        loading: true,
      };
    case GET_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: payload,
        loading: false,
      };
    case GET_PERMISSION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default adminReducer;
