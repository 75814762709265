import {
  FETCH_INGREDIENTS,
  FETCH_INGREDIENTS_SUCCESS,
  FETCH_INGREDIENTS_ERROR,
  SET_INGREDIENT_SEARCH,
  SET_INGREDIENTS_PAGE,
  SET_INGREDIENTS_SIZE_PER_PAGE,
  SET_INGREDIENTS_SORT_FIELD,
  SET_INGREDIENTS_SORT_ORDER,
  ADD_INGREDIENT,
  ADD_INGREDIENT_SUCCESS,
  ADD_INGREDIENT_ERROR,
  EDIT_INGREDIENT,
  EDIT_INGREDIENT_SUCCESS,
  EDIT_INGREDIENT_ERROR,
  GET_ALL_AISLE,
  GET_ALL_AISLE_SUCCESS,
  GET_ALL_AISLE_ERROR,
  GET_ALL_INGREDIENTS_BY_NAME,
} from "./actionTypes";

const initialState = {
  loading: false,
  ingredients: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  sortOrder: "",
  sortField: "",
  search: "",
  loadingIngredientsSave: false,
  ingredientsListWithName: [],
  aisles: [],
};

const ingredientReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set Ingredients PageNo
    case SET_INGREDIENTS_PAGE:
      return {
        ...state,
        page: payload,
      };

    // set Ingredients siePerPage
    case SET_INGREDIENTS_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };

    // set Ingredients sortField
    case SET_INGREDIENTS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    // set Ingredients sortOrder
    case SET_INGREDIENTS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };

    // set Ingredients search
    case SET_INGREDIENT_SEARCH:
      return {
        ...state,
        search: payload,
      };

    //fetch Ingredients
    case FETCH_INGREDIENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INGREDIENTS_SUCCESS:
      return {
        ...state,
        ingredients: payload.rows,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_INGREDIENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    //add Ingredients
    case ADD_INGREDIENT:
      return {
        ...state,
        loadingIngredientsSave: true,
      };
    case ADD_INGREDIENT_SUCCESS:
      return {
        ...state,
        loadingIngredientsSave: false,
      };
    case ADD_INGREDIENT_ERROR:
      return {
        ...state,
        loadingIngredientsSave: false,
      };

    // edit Ingredients
    case EDIT_INGREDIENT:
      return {
        ...state,
        loadingIngredientsSave: true,
      };
    case EDIT_INGREDIENT_SUCCESS:
      return {
        ...state,
        loadingIngredientsSave: false,
      };
    case EDIT_INGREDIENT_ERROR:
      return {
        ...state,
        loadingIngredientsSave: false,
      };

    // get all aisles
    case GET_ALL_AISLE:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_AISLE_SUCCESS:
      return {
        ...state,
        loading: false,
        aisles: payload,
      };
    case GET_ALL_AISLE_ERROR:
      return {
        ...state,
        loading: false,
      };
    // get all ingredient by name
    case GET_ALL_INGREDIENTS_BY_NAME:
      return {
        ...state,
        ingredientsListWithName: payload.ingredientsList
      }
    default:
      return state;
  }
};

export default ingredientReducer;
