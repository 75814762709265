import {
  ADD_RECIPE_CATEGORY,
  ADD_RECIPE_CATEGORY_ERROR,
  ADD_RECIPE_CATEGORY_SUCCESS,
  EDIT_RECIPE_CATEGORY,
  EDIT_RECIPE_CATEGORY_ERROR,
  EDIT_RECIPE_CATEGORY_SUCCESS,
  FETCH_RECIPE_CATEGORIES,
  FETCH_RECIPE_CATEGORIES_ERROR,
  FETCH_RECIPE_CATEGORIES_SUCCESS,
  SET_RECIPE_CATEGORIES_PAGE,
  SET_RECIPE_CATEGORIES_SEARCH,
  SET_RECIPE_CATEGORIES_SIZE_PER_PAGE,
  SET_RECIPE_CATEGORIES_SORT_FIELD,
  SET_RECIPE_CATEGORIES_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  loading: false,
  recipeCategories: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  sortOrder: "",
  sortField: "",
  search: "",
  loadingRecipeCategories: false,
};

const recipeCategoriesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //set recipe categories page
    case SET_RECIPE_CATEGORIES_PAGE:
      return {
        ...state,
        page: payload,
      };
    //set recipe categories sizePerPage
    case SET_RECIPE_CATEGORIES_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    //set recipe categories sortField
    case SET_RECIPE_CATEGORIES_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    // set recipe categories sortOrder
    case SET_RECIPE_CATEGORIES_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // set recipe categories search
    case SET_RECIPE_CATEGORIES_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // fetch all recipe categories
    case FETCH_RECIPE_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RECIPE_CATEGORIES_SUCCESS:
      return {
        ...state,
        recipeCategories: payload.rows,
        totalSize: payload.count,
        loading: false,
      };
    case FETCH_RECIPE_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };
    //add recipe category
    case ADD_RECIPE_CATEGORY:
      return {
        ...state,
        loadingRecipeCategories: true,
      };
    case ADD_RECIPE_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingRecipeCategories: false,
      };
    case ADD_RECIPE_CATEGORY_ERROR:
      return {
        ...state,
        loadingRecipeCategories: false,
      };
    // edit recipe category
    case EDIT_RECIPE_CATEGORY:
      return {
        ...state,
        loadingRecipeCategories: true,
      };
    case EDIT_RECIPE_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingRecipeCategories: false,
      };
    case EDIT_RECIPE_CATEGORY_ERROR:
      return {
        ...state,
        loadingRecipeCategories: false,
      };
    default:
      return state;
  }
};

export default recipeCategoriesReducer;
