// set sortField of products
export const SET_PRODUCTS_SORT_FIELD = "SET_PRODUCTS_SORT_FIELD";

// set sortOrder of products
export const SET_PRODUCTS_SORT_ORDER = "SET_PRODUCTS_SORT_ORDER";

// set pageNo of products
export const SET_PRODUCTS_PAGE = "SET_PRODUCTS_PAGE";

// set sizePerPage of products
export const SET_PRODUCTS_SIZE_PER_PAGE = "SET_PRODUCTS_SIZE_PER_PAGE";

// set search of products
export const SET_PRODUCTS_SEARCH = "SET_PRODUCTS_SEARCH";

//fetch products
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR";

// Add products
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";

// edit products
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";

//drag and drop product
export const DRAG_AND_DROP_PRODUCT = "DRAG_AND_DROP_PRODUCT";
