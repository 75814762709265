import { combineReducers } from "redux";
import Login from "modules/authentication/store/reducers";
import Ingredients from "modules/ingredients/store/reducers";
import Admins from "modules/admin/store/reducers";
import Aisles from "modules/categories/components/Aisles/store/reducers";
import RecipeCategories from "modules/categories/components/RecipeCategories/store/reducers";
import RecipeTags from "modules/categories/components/RecipeTags/store/reducers";
import Recipes from "modules/recipes/store/reducers";
import Guides from "modules/guides/store/reducers";
import Products from "modules/products/store/reducers";
import PushNotifications from "modules/pushNotifications/store/reducers";
import Faqs from "modules/faq/store/reducers";
import Users from 'modules/users/store/reducers'

import Common from "store/common/reducers";

const rootReducer = combineReducers({
  Login,
  Ingredients,
  Admins,
  Aisles,
  RecipeCategories,
  RecipeTags,
  Recipes,
  Guides,
  Products,
  PushNotifications,
  Faqs,
  Users,
  //common
  Common,
});

export default rootReducer;
