// set recipe page
export const SET_RECIPE_PAGE = "SET_RECIPE_PAGE";

// set recipe sizePerPage
export const SET_RECIPE_SIZE_PER_PAGE = "SET_RECIPE_SIZE_PER_PAGE";

// set recipe sizePerPage
export const SET_RECIPE_SORT_FIELD = "SET_RECIPE_SORT_FIELD";

// set recipe sortOrder
export const SET_RECIPE_SORT_ORDER = "SET_RECIPE_SORT_ORDER";

// set recipe search
export const SET_RECIPE_SEARCH = "SET_RECIPE_SEARCH";

// filter By status
export const FILTER_BY_STATUS = "FILTER_BY_STATUS";

// get all recipe categories
export const GET_ALL_RECIPE_CATEGORIES = "GET_ALL_RECIPE_CATEGORIES";

// fetch all filters
export const FETCH_FILTERS = "FETCH_FILTERS";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const FETCH_FILTERS_ERROR = "FETCH_FILTERS_ERROR";

// fetch all recipes
export const FETCH_RECIPE = "FETCH_RECIPES";
export const FETCH_RECIPE_SUCCESS = "FETCH_RECIPE_SUCCESS";
export const FETCH_RECIPE_ERROR = "FETCH_RECIPE_ERROR";

// set recipe status
export const SET_RECIPE_STATUS = "SET_RECIPE_STATUS";

// add recipe
export const ADD_RECIPE = "ADD_RECIPE";
export const ADD_RECIPE_SUCCESS = "ADD_RECIPE_SUCCESS";
export const ADD_RECIPE_ERROR = "ADD_RECIPE_ERROR";

// edit recipe
export const EDIT_RECIPE = "EDIT_RECIPE";
export const EDIT_RECIPE_SUCCESS = "EDIT_RECIPE_SUCCESS";
export const EDIT_RECIPE_ERROR = "EDIT_RECIPE_ERROR";

// list all recipe dropdown data
export const GET_RECIPE_DATA = "GET_RECIPE_DATA";
export const GET_RECIPE_DATA_SUCCESS = "GET_RECIPE_DATA_SUCCESS";
export const GET_RECIPE_DATA_ERROR = "GET_RECIPE_DATA_ERROR";

// get recipe by categories filter
export const GET_RECIPE_BY_CATEGORY_FILTER = "GET_RECIPE_BY_CATEGORY_FILTER";