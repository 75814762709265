import {
  SET_RECIPE_TAGS_PAGE,
  SET_RECIPE_TAGS_SIZE_PER_PAGE,
  SET_RECIPE_TAGS_SORT_ORDER,
  SET_RECIPE_TAGS_SORT_FILED,
  SET_RECIPE_TAGS_SEARCH,
  FETCH_ALL_RECIPE_TAGS,
  FETCH_ALL_RECIPE_TAGS_SUCCESS,
  FETCH_ALL_RECIPE_TAGS_ERROR,
  EDIT_RECIPE_TAGS,
  EDIT_RECIPE_TAGS_SUCCESS,
  EDIT_RECIPE_TAGS_ERROR,
  ADD_RECIPE_TAGS,
  ADD_RECIPE_TAGS_SUCCESS,
  ADD_RECIPE_TAGS_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  recipeTags: [],
  sortField: "",
  sortOrder: "",
  page: 1,
  sizePerPage: 10,
  totalSize: 0,
  search: "",
  loadingAddEditRecipeTag: false,
};

const recipeTagsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set recipe tag page
    case SET_RECIPE_TAGS_PAGE:
      return {
        ...state,
        page: payload,
      };
    // set recipe tag sizePerPage
    case SET_RECIPE_TAGS_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    // set recipe tag sortOrder
    case SET_RECIPE_TAGS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // set recipe tag sortField
    case SET_RECIPE_TAGS_SORT_FILED:
      return {
        ...state,
        sortField: payload,
      };
    // set recipe tag search
    case SET_RECIPE_TAGS_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // fetch all recipe tags
    case FETCH_ALL_RECIPE_TAGS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_RECIPE_TAGS_SUCCESS:
      return {
        ...state,
        recipeTags: payload.rows,
        totalSize: payload.count,
        loading: false,
      };
    case FETCH_ALL_RECIPE_TAGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    //add recipe tag
    case ADD_RECIPE_TAGS:
      return {
        ...state,
        loadingAddEditRecipeTag: true,
      };
    case ADD_RECIPE_TAGS_SUCCESS:
      return {
        ...state,
        loadingAddEditRecipeTag: false,
      };
    case ADD_RECIPE_TAGS_ERROR:
      return {
        ...state,
        loadingAddEditRecipeTag: false,
      };
    //edit recipe tag
    case EDIT_RECIPE_TAGS:
      return {
        ...state,
        loadingAddEditRecipeTag: true,
      };
    case EDIT_RECIPE_TAGS_SUCCESS:
      return {
        ...state,
        loadingAddEditRecipeTag: false,
      };
    case EDIT_RECIPE_TAGS_ERROR:
      return {
        ...state,
        loadingAddEditRecipeTag: false,
      };
    default:
      return state;
  }
};

export default recipeTagsReducer;
