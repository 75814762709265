//fetch Ingredients
export const FETCH_INGREDIENTS = "FETCH_INGREDIENTS";
export const FETCH_INGREDIENTS_SUCCESS = "FETCH_INGREDIENTS_SUCCESS";
export const FETCH_INGREDIENTS_ERROR = "FETCH_INGREDIENTS_ERROR";

// set sortField of ingredients
export const SET_INGREDIENTS_SORT_FIELD = "SET_INGREDIENTS_SORT_FIELD";

// set sortOrder of ingredients
export const SET_INGREDIENTS_SORT_ORDER = "SET_INGREDIENTS_SORT_ORDER";

// set pageNo of ingredients
export const SET_INGREDIENTS_PAGE = "SET_INGREDIENTS_PAGE";

// set sizePerPage of ingredients
export const SET_INGREDIENTS_SIZE_PER_PAGE = "SET_INGREDIENTS_SIZE_PER_PAGE";

// set search of ingredients
export const SET_INGREDIENT_SEARCH = "SET_INGREDIENT_SEARCH";

// Add Ingredients
export const ADD_INGREDIENT = "ADD_INGREDIENT";
export const ADD_INGREDIENT_SUCCESS = "ADD_INGREDIENT_SUCCESS";
export const ADD_INGREDIENT_ERROR = "ADD_INGREDIENT_ERROR";

// edit Ingredients
export const EDIT_INGREDIENT = "EDIT_INGREDIENT";
export const EDIT_INGREDIENT_SUCCESS = "EDIT_INGREDIENT_SUCCESS";
export const EDIT_INGREDIENT_ERROR = "EDIT_INGREDIENT_ERROR";

// get all aisle
export const GET_ALL_AISLE = "GET_ALL_AISLE";
export const GET_ALL_AISLE_SUCCESS = "GET_ALL_AISLE_SUCCESS";
export const GET_ALL_AISLE_ERROR = "GET_ALL_AISLE_ERROR";

// get all ingredient by name
export const GET_ALL_INGREDIENTS_BY_NAME = "GET_ALL_INGREDIENTS_BY_NAME";
