import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  API_ERROR,
  SET_LOGIN_MESSAGE,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: "",
  user: null,
  isAuth: false,
  success: "",
  userPermissions: [],
  loadingProfile: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    // set login message
    case SET_LOGIN_MESSAGE:
      return {
        ...state,
        success: action.payload,
        loading: false,
      };
    // login user
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        user: action.payload,
        loading: false,
      };
    // logout user
    case LOGOUT_USER:
      return { ...state, user: null, isAuth: false, userPermissions: [] };
    // api error
    case API_ERROR:
      return { ...state, error: action.payload, loading: false };
    //fetch user profile
    case FETCH_PROFILE:
      return {
        ...state,
        loadingProfile: true,
      };
    case FETCH_PROFILE_SUCCESS:
      const permissions = action.payload.permission;
      return {
        ...state,
        loadingProfile: false,
        user: action.payload,
        isAuth: true,
        userPermissions: permissions,
      };
    case FETCH_PROFILE_ERROR:
      return {
        ...state,
        loadingProfile: false,
        isAuth: false,
      };
    default:
      return state;
  }
};

export default login;
