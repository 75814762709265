// set recipe tags sortField
export const SET_RECIPE_TAGS_SORT_FILED = "SET_RECIPE_TAGS_SORT_FILED";

// set recipe tags sortOrder
export const SET_RECIPE_TAGS_SORT_ORDER = "SET_RECIPE_TAGS_SORT_ORDER";

// set recipe tags sizePerPage
export const SET_RECIPE_TAGS_SIZE_PER_PAGE = "SET_RECIPE_TAGS_SIZE_PER_PAGE";

// set recipe tags page
export const SET_RECIPE_TAGS_PAGE = "SET_RECIPE_TAGS_PAGE";

// set recipe tags search
export const SET_RECIPE_TAGS_SEARCH = "SET_RECIPE_TAGS_SEARCH";

// get all recipe tags
export const FETCH_ALL_RECIPE_TAGS = "FETCH_ALL_RECIPE_TAGS";
export const FETCH_ALL_RECIPE_TAGS_SUCCESS = "FETCH_ALL_RECIPE_TAGS_SUCCESS";
export const FETCH_ALL_RECIPE_TAGS_ERROR = "FETCH_ALL_RECIPE_TAGS_ERROR";

// add recipe tag
export const ADD_RECIPE_TAGS = "ADD_RECIPE_TAGS";
export const ADD_RECIPE_TAGS_SUCCESS = "ADD_RECIPE_TAGS_SUCCESS";
export const ADD_RECIPE_TAGS_ERROR = "ADD_RECIPE_TAGS_ERROR";

// edit recipe tag
export const EDIT_RECIPE_TAGS = "EDIT_RECIPE_TAGS";
export const EDIT_RECIPE_TAGS_SUCCESS = "EDIT_RECIPE_TAGS_SUCCESS";
export const EDIT_RECIPE_TAGS_ERROR = "EDIT_RECIPE_TAGS_ERROR";
