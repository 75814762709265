// set sortField of recipes categories
export const SET_RECIPE_CATEGORIES_SORT_FIELD =
  "SET_RECIPE_CATEGORIES_SORT_FIELD";

// set sortOrder of recipes categories
export const SET_RECIPE_CATEGORIES_SORT_ORDER =
  "SET_RECIPE_CATEGORIES_SORT_ORDER";

// set pageNo of recipes categories
export const SET_RECIPE_CATEGORIES_PAGE = "SET_RECIPE_CATEGORIES_PAGE";

// set sizePerPage of recipes categories
export const SET_RECIPE_CATEGORIES_SIZE_PER_PAGE =
  "SET_RECIPE_CATEGORIES_SIZE_PER_PAGE";

// set search of recipes categories
export const SET_RECIPE_CATEGORIES_SEARCH = "SET_RECIPE_CATEGORIES_SEARCH";

// fetch all recipes categories
export const FETCH_RECIPE_CATEGORIES = "FETCH_RECIPE_CATEGORIES";
export const FETCH_RECIPE_CATEGORIES_SUCCESS =
  "FETCH_RECIPE_CATEGORIES_SUCCESS";
export const FETCH_RECIPE_CATEGORIES_ERROR = "FETCH_RECIPE_CATEGORIES_ERROR";

// add recipe categories
export const ADD_RECIPE_CATEGORY = "ADD_RECIPE_CATEGORY";
export const ADD_RECIPE_CATEGORY_SUCCESS = "ADD_RECIPE_CATEGORY_SUCCESS";
export const ADD_RECIPE_CATEGORY_ERROR = "ADD_RECIPE_CATEGORY_ERROR";

// edit recipe categories
export const EDIT_RECIPE_CATEGORY = "EDIT_RECIPE_CATEGORY";
export const EDIT_RECIPE_CATEGORY_SUCCESS = "EDIT_RECIPE_CATEGORY_SUCCESS";
export const EDIT_RECIPE_CATEGORY_ERROR = "EDIT_RECIPE_CATEGORY_ERROR";
