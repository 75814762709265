// Fetch All Faq
export const GET_FAQS = "GET_FAQS";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_ERROR = "GET_FAQS_ERROR";

// Add Faq
export const ADD_FAQ = "ADD_FAQ";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_ERROR = "ADD_FAQ_ERROR";

//edit Faq
export const EDIT_FAQ = "EDIT_FAQ";
export const EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";
export const EDIT_FAQ_ERROR = "EDIT_FAQ_ERROR";

//drag and drop FAQ
export const DRAG_AND_DROP_FAQ = "DRAG_AND_DROP_FAQ";
