//fetch guides
export const FETCH_GUIDES = "FETCH_GUIDES";
export const FETCH_GUIDES_SUCCESS = "FETCH_GUIDES_SUCCESS";
export const FETCH_GUIDES_ERROR = "FETCH_GUIDES_ERROR";

// set sortField of guides
export const SET_GUIDES_SORT_FIELD = "SET_GUIDES_SORT_FIELD";

// set sortOrder of guides
export const SET_GUIDES_SORT_ORDER = "SET_GUIDES_SORT_ORDER";

// set pageNo of guides
export const SET_GUIDES_PAGE = "SET_GUIDES_PAGE";

// set sizePerPage of guides
export const SET_GUIDES_SIZE_PER_PAGE = "SET_GUIDES_SIZE_PER_PAGE";

// set search of guides
export const SET_GUIDES_SEARCH = "SET_GUIDES_SEARCH";

// Add Guides
export const ADD_GUIDES = "ADD_GUIDES";
export const ADD_GUIDES_SUCCESS = "ADD_GUIDES_SUCCESS";
export const ADD_GUIDES_ERROR = "ADD_GUIDES_ERROR";

// edit Guides
export const EDIT_GUIDE = "EDIT_GUIDE";
export const EDIT_GUIDES_SUCCESS = "EDIT_GUIDES_SUCCESS";
export const EDIT_GUIDES_ERROR = "EDIT_GUIDES_ERROR";

// drag guide
export const DRAG_AND_DROP_GUIDE = "DRAG_AND_DROP_GUIDE"

// Draft / LIVE Toggle guide
export const SET_GUIDE_STATUS = "SET_GUIDE_STATUS"
