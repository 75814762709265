//set admin sort field
export const SET_ADMIN_SORT_FIELD = "SET_ADMIN_SORT_FIELD";

//set admin sort order
export const SET_ADMIN_SORT_ORDER = "SET_ADMIN_SORT_ORDER";

//set admin pageNo
export const SET_ADMIN_PAGE = "SET_ADMIN_PAGE";

//set admin sizePerPage
export const SET_ADMIN_SIZE_PER_PAGE = "SET_ADMIN_SIZE_PER_PAGE";

//set admin search
export const SET_ADMIN_SEARCH = "SET_ADMIN_SEARCH";

// fetch admin
export const FETCH_ADMINS = "FETCH_ADMINS";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_ERROR = "FETCH_ADMINS_ERROR";

//add admin
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_ERROR = "ADD_ADMIN_ERROR";

//edit admin
export const EDIT_ADMIN = "EDIT_ADMIN";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_ERROR = "EDIT_ADMIN_ERROR";

// get permissions
export const GET_PERMISSION = "GET_PERMISSION";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_ERROR = "GET_PERMISSION_ERROR";
